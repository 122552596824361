(function ($) {
	$(document).ready(function () {

		$(".page-menu .menu-item a").on('click', function (e) {
			if($(document).width() <= 1240){
				$(".page-header").removeClass("active");
			}
		});

		/*****************************************
		 *
		 */
		$(document).scroll(function() {
			if ($(document).scrollTop() >= 20) {
				$(".page-header").addClass("scroll");

			} else {
				$(".page-header").removeClass("scroll");
			}
		});


		/******************************
		 *
		 * @type {boolean}
		 */
		let isDemolice = false;
		let isZem = false;
		let isAutodoprava = false;
		let isPlocha = false;


		slideIcons();

		$(document).on('scroll', function () {
			slideIcons();
		});

		function slideIcons(){
			let height = $(window).height() * 0.5;
			let scrollHeight = $(document).scrollTop();

			if (scrollHeight >= $('#demolice').position().top - height && scrollHeight <=  $('#demolice').position().top + $('#demolice').outerHeight(true) - height) {

				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#demolice"]').addClass("active");
				if(!isDemolice) {
					isDemolice = true;

					let demoliceProcentWhite = $("#demolice .images-wrapper").width() * 0.12 + 163;
					TweenMax.to("#demolice .white", 3, {x: demoliceProcentWhite, rotation: 360});

					let demoliceProcentRed = $("#demolice .images-wrapper").width() * 0.044 + 66;
					TweenMax.to("#demolice .red", 3, {x: demoliceProcentRed, rotation: 360});
				}
			}
			else if ($(document).scrollTop() >= $('#zem').position().top - height && scrollHeight <= $('#zem').position().top + $('#zem').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#zem"]').addClass("active");
				if(!isZem) {
					isZem = true;

					let zemProcentWhite = $("#zem .images-wrapper").width() * 0.109 + 56;
					TweenMax.to("#zem .white", 3, {x: -zemProcentWhite, rotation: -430});

					let zemProcentRed = $("#zem .images-wrapper").width() * (-0.055) + 121;
					TweenMax.to("#zem .red", 3, {x: -zemProcentRed, rotation: -300});
				}
			}
			else if ($(document).scrollTop() >= $('#autodoprava').position().top - height && scrollHeight <= $('#autodoprava').position().top + $('#autodoprava').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#autodoprava"]').addClass("active");
				if(!isAutodoprava) {
					isAutodoprava = true;

					let autodopravaProcentWhite = $("#autodoprava .images-wrapper").width() * 0.112 + 121;
					TweenMax.to("#autodoprava .white", 3, {x: autodopravaProcentWhite, rotation: 314});

					let autodopravaProcentRed = $("#autodoprava .images-wrapper").width() * (0.350) + 59;
					TweenMax.to("#autodoprava .red", 3, {x: autodopravaProcentRed, rotation: 460});
				}
			}
			else if ($(document).scrollTop() >= $('#plocha').position().top - height && scrollHeight <= $('#plocha').position().top + $('#plocha').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#plocha"]').addClass("active");
				if(!isPlocha) {
					isPlocha = true;

					let plochaProcentWhite = $("#plocha .images-wrapper").width() * (-0.0734) + 169;
					TweenMax.to("#plocha .white", 3, {x: -plochaProcentWhite, rotation: -372});

					let plochaProcentRed = $("#plocha .images-wrapper").width() * (0.185) + 71;
					TweenMax.to("#plocha .red", 3, {x: -plochaProcentRed, rotation: -337});
				}
			}
			else if ($(document).scrollTop() >= $('#intro').position().top - height && scrollHeight <= $('#intro').position().top + $('#intro').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#intro"]').addClass("active");
			}
			else if ($(document).scrollTop() >= $('#other-services').position().top - height && scrollHeight <= $('#other-services').position().top + $('#other-services').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#other-services"]').addClass("active");
			}
			else if ($(document).scrollTop() >= $('footer').position().top - height && scrollHeight <= $('footer').position().top + $('footer').outerHeight(true) - height) {
				$('.menu-list .menu-item a.active').removeClass("active");
				$('.menu-list .menu-item a[href$="#contact"]').addClass("active");
			}
			else{
				$('.menu-list .menu-item a.active').removeClass("active");
			}
		}

		/*let demoliceProcentWhite = $("#demolice .images-wrapper").width() * 0.12 + 163;
		TweenMax.to("#demolice .white", 3, {x: demoliceProcentWhite, rotation:360});

		let demoliceProcentRed = $("#demolice .images-wrapper").width() * 0.044 + 66;
		TweenMax.to("#demolice .red", 3, {x: demoliceProcentRed, rotation:360});

		let zemProcentWhite = $("#zem .images-wrapper").width() * 0.109 + 56;
		TweenMax.to("#zem .white", 3, {x: -zemProcentWhite, rotation:-430});

		let zemProcentRed = $("#zem .images-wrapper").width() * (-0.055) + 121;
		TweenMax.to("#zem .red", 3, {x: -zemProcentRed, rotation: -300});

		let autodopravaProcentWhite = $("#autodoprava .images-wrapper").width() * 0.112 + 121;
		TweenMax.to("#autodoprava .white", 3, {x: autodopravaProcentWhite, rotation:314});

		let autodopravaProcentRed = $("#autodoprava .images-wrapper").width() * (0.278) + 59;
		TweenMax.to("#autodoprava .red", 3, {x: autodopravaProcentRed, rotation:460});

		let plochaProcentWhite = $("#plocha .images-wrapper").width() * (-0.0734) + 169;
		TweenMax.to("#plocha .white", 3, {x: -plochaProcentWhit, rotation: -372});

		let plochaProcentRed = $("#plocha .images-wrapper").width() * (0.185) + 71;
		TweenMax.to("#plocha .red", 3, {x: -plochaProcentWhit, rotation: -337});*/


		/******************************
		 * pomale přesunutí při vnitřním odkazu
		 */
		$('a').on('click', function (e) {
			console.log("testtttttt");
			let href = $(this).attr('href');
			if (href !== undefined) {
				let tmp = href.split('#');
				let mobilPlust = 0;
				if($(document).width() < 800){
					mobilPlust = 80;
				}

				mobilPlust = ($('#' + tmp[1]).offset().top - mobilPlust) + "px";

				console.log(mobilPlust);
				if (tmp[1] != undefined && $('#' + tmp[1]).length) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: (mobilPlust)
					});
				}
			}
		});

		/*************************************
		 * hamburger
		 */

		$('.hamburger').on('click', function (e) {
			$(this).toggleClass("active");
			$(".page-header").toggleClass("active");
		});


		/********************************************
		 * FORM
		 */
		$('.input.upload .caption').on('click', function () {
			$(this).parent().find('input').trigger('click');
		});

		$('.input.upload input').on('change', function () {
			$(this).parent().find('.value .v').text($(this).val());
		});

		$('.input.upload .value .d').on('click', function () {
			$(this).parents('.input').find('input').val('').trigger('change');
		});

		$('body').on('change', 'input:not([type=checkbox]), textarea', function (e) {
			if ($(this).val() != '')
				$(this).addClass('filled').parent().addClass('filled');
			else
				$(this).removeClass('filled').parent().removeClass('filled');
		}).on('focus', 'input:not([type=checkbox]), textarea', function (e) {
			$(this).parent().addClass('focus');
		}).on('blur', 'input:not([type=checkbox]), textarea', function (e) {
			$(this).parent().removeClass('focus');
		});

	});
})(jQuery);

function initMap() {
	var uluru = {lat: -25.344, lng: 131.036};

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 4,
		center: uluru
	});
}
